import { createPinia } from "pinia";
import piniaPluginPersist from "pinia-plugin-persist";
import { createPersistedState } from "pinia-plugin-persistedstate";
const store = createPinia();
store.use(createPersistedState());

store.use(piniaPluginPersist);
export * from "./userStore";
export { store };
export default store;
