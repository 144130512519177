import axios from "axios";
import { Toast } from "vant";
import router from "../router";

import { app } from "../main.js";
import { getCurrentInstance, ref } from "vue";
import { getRouterStore } from "../store";
const currentInstance = getCurrentInstance();
const proxy = ref(currentInstance?.proxy);
// 请求  baseURL: "/api"  本地连接调试
// 请求  baseURL: "https://point-store.api.taohualian168.net/api", 正式
const request = axios.create({
  baseURL: "https://point-store.api.taohualian168.net/api",
  // baseURL: "https://point-store.dev.api.taohualian168.net/api",
  // baseURL: "/api",
  timeout: 300000,
});

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    // console.log(user, "user");
    if (config && config.headers) {
      config.headers["Content-Type"] = "application/json;charset=utf-8";
    }
    if (user != null) {
      config.headers["Authorization"] = "Bearer " + user.token; // 设置请求头
    }
   

    // if (userInfo && userInfo.token && userInfo.isLogin) {
    //   config.headers["Authorization"] = "Bearer " + user.token;
    // }
    config.headers.shopid = 2;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  (response) => {
    let res = response.data;
    // console.log(response);
    // Toast.fail("服务端异常！");
    if (response.status === 200) {
      return Promise.resolve(res);
    }
    // 如果是返回的文件
    if (response.status === 401) {
      // Toast.fail('服务端异常！')
      // if (router.currentRoute.path !== '/login') {
      //     router.replace({ path: '/login' })
      // }
      return Promise.reject(res.errors);
    }
    if (response.status === 403) {
      // Toast.fail('服务端异常！')
      // if (router.currentRoute.path !== '/login') {
      //     router.replace({ path: '/login' })
      // }
      return Promise.reject(res.errors);
    }
    if (response.status === 404) {
      router.replace({ path: "/error" });
    }
    if (response.status === 400) {
      router.replace({ path: "/error" });
    }
    if (response.config.responseType === "blob") {
      return res;
    }
    // 兼容服务端返回的字符串数据
    if (typeof response === "string") {
      // console.log(res, "返回字符串");
      res = res ? JSON.parse(res) : res;
    }
    if (response.status === 500) {
      // Toast.fail("服务端异常！");
      router.replace({ path: "/error" });
      return Promise.reject(res.errors);
    }
    return res;
  },
  (error) => {
    router.replace({ path: "/error" });
    // Toast.fail("服务端异常！");
    return Promise.reject(error);
  }
);

export default request;
