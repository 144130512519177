import { defineStore } from "pinia";
import { store } from "@/store";
const state = {
  userInfo: {
    companyApi: "",
    companyCode: "",
    companyName: "",
    mallUserAvatarUrl: "",
    mallUserId: "",
    mallUserName: "",
    timeStamp: "",
    token: "",
  },
};
export const userStore = defineStore("user", {
  state: () => state,
  getters: {
    userInfo: (state) => state.userInfo,
  },
  actions: {
    // 获取值用导出的getRouterStore。wxLogin()方法
    // wxLogin() {
    //   return (state.type = 2222);
    // },
  },
  persist: true,
});
export function getRouterStore() {
  return userStore(store);
}
