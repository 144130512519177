import { createApp } from "vue";

import {
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Divider,
  Popup,
  Overlay,
  Loading,
  Dialog,
  ContactCard,
  Form,
  AddressEdit,
  AddressList,
  Field,
  CellGroup,
  Cell,
  SwipeCell,
  Icon,
  Stepper,
  Card,
  Checkbox,
  CheckboxGroup,
  Button,
  Swipe,
  SwipeItem,
  PullRefresh,
  List,
  Tab,
  Tabs,
  SubmitBar,
  Tag,
  Tabbar,
  TabbarItem,
  Cascader,
  Switch,
  Sticky,
  ActionSheet,
  Skeleton,
  Image as VanImage,
  Lazyload,
  CountDown,
  // SkeletonTitle,
  // SkeletonImage,
  // SkeletonAvatar,
  // SkeletonParagraph,
  Toast,
  Area,
  NoticeBar
} from "vant";
import "vant/es/toast/style";
import App from "./App.vue";
import store from "./store";
// import { createPinia } from "pinia";
import router from "./router";
import "lib-flexible/flexible";
import "vant/lib/index.css"; // 全局引入样式
// import Vue from "vue";
// import "vant/es/toast/style";
// import "vant/es/notify/style";
import { wxInit } from "./utils/wxHelper";
export const app = createApp(App); // 创建实例

app.config.globalProperties.$toast = Toast;
// 全局过滤器
// app.config.globalProperties.$filters = {
//   prefix(url) {
//     if (url && url.startsWith("http")) {
//       return url;
//     } else {
//       url = `https://testygw.api.taohuasanbao.com/svc${url}`;
//       return url;
//     }
//   },
// };
app
  .use(ActionBarButton)
  .use(ActionBarIcon)
  .use(ActionBar)
  .use(Divider)
  .use(Popup)
  .use(Overlay)
  .use(Loading)
  .use(Dialog)
  .use(Toast)
  .use(ContactCard)
  .use(Form)
  .use(AddressEdit)
  .use(AddressList)
  .use(Field)
  .use(CellGroup)
  .use(Cell)
  .use(SwipeCell)
  .use(Icon)
  .use(Stepper)
  .use(Card)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(PullRefresh)
  .use(List)
  .use(Tab)
  .use(Tabs)
  .use(SubmitBar)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Skeleton)
  .use(Tag)
  .use(Tabbar)
  .use(TabbarItem)
  .use(List)
  .use(Switch)
  .use(Sticky)
  .use(ActionSheet)
  .use(Cascader)
  .use(Area)
  .use(NoticeBar)
  .use(Lazyload)
  .use(VanImage)
  .use(CountDown);
app.use(router);
app.use(store);
// router.afterEach((to,from)=>{
  wxInit()
// })

app.mount("#app");
