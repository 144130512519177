import wx from "weixin-js-sdk";
import { GetJsApi } from '@/service/jsApi'
// 微信支付用的方法 必须先调过wx.config 才能调取wx.chooseWXPay
const wxInit = async() => {
  console.log('进入');
  await GetJsApi().then((res) => {
    if (res.value != '' &&res.value != null && res.value != undefined) {
      wx.config({
        debugger:true,
        appId: res.value.appId,
        nonceStr: res.value.nonceStr,
        signature: res.value.signature,
        timeStamp: res.value.timeStamp,
        jsApiList: ["chooseWXPay", "hideMenuItems", "hideAllNonBaseMenuItem", "hideOptionMenu"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.ready(function () {
        // Notify({ type: "success", message: "微信加载成功" });
     
        console.log("微信加载成功");
        wx.hideMenuItems({
          menuList: ["menuItem:share:appMessage", "menuItem:share:timeline", "menuItem:share:qq", "menuItem:share:weiboApp", "menuItem:favorite", "menuItem:share:facebook", "menuItem:share:QZone", "menuItem:copyUrl", "menuItem:originPage", "menuItem:openWithQQBrowser", "menuItem:openWithSafari", "menuItem:share:email", "menuItem:share:brand"] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        })
        wx.hideAllNonBaseMenuItem();
        wx.hideOptionMenu();
      });
      wx.error(function (res) {
       
        console.log("微信加载失败");
        // Notify({ type: "danger", message: "微信加载失败" });
      });
    }else{
      console.log('数据加载失败');
    }
  
  });
};
const wxPay = (data) => {
  console.log('pay进入');
  console.log(data,'参数');
  return new Promise((resolve, reject) => {
    wx.chooseWXPay({
      debugger:true,
      appId:data.appId,
      timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
      package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      paySign: data.paySign, // 支付签名
      success: function (res) {
        console.log(res)
        resolve(res);
      },
      fail: function (res) {
        console.log(res)
        reject(res);
      },
      cancel: function (res) {
        console.log(res)
        reject(res);
      },
      complete: function (res) {
        console.log(res)
        reject(res)
      }
    });
  });
};
export { wxPay, wxInit };
