import axios from '../utils/request'
//  获取jsApi
export function GetJsApi() {
  return axios.get(`/accumulated/LuckDraw/GetJsApi`);
}
// 根据code获取openid
export function GetUserOpenId(data) {
  return axios.post("/accumulated/Login/GetUserOpenId", data);
}
// 调取支付，获取微信支付参数 win新增参数
export function GetUnifiedOrder(data) {
  return axios.post("/accumulated/WeiXinPay/GetUnifiedOrder", data);
}
// 调取测试支付，win新增
export function TestUnifiedOrder(data) {
  return axios.post("/accumulated/WeiXinPay/TestUnifiedOrder", data);
}
// 判断是否有openId
export function GetOpenIds() {
  return axios.get(`/accumulated/LuckDraw/GetUserOpenId`);
}
